const getMainMenu = () => {
  const navigation = [
    {
      id: "shopParts",
      primary: "Shop",
      secondary: "Parts",
      icon: "parts",
      onClick: {
        type: "setDrawerState",
        payload: {
          drawerState: {
            isDrawerOpen: true,
          },
          drawerAction: { type: "gotoShopNavigation" },
        },
      },
    },
    {
      id: "toolboxArticles",
      primary: "Toolbox",
      secondary: "Articles",
      icon: "toolbox",
      href: null,
      customOnClick: (dispatch) => {
        analytics.track("Clicked", {
          element: "header drawer toolbox",
        });

        dispatch({
          type: "setDrawerState",
          payload: {
            drawerState: {
              isDrawerOpen: true,
            },
            drawerAction: {
              type: "addNavigation",
              payload: {
                component: "FeaturedArticleNavigation",
                menu: "toolboxMainMenu",
                props: {
                  isToolbox: true,
                },
              },
            },
          },
        });
      },
    },
    {
      id: "myList",
      primary: "My",
      secondary: "List",
      icon: "buildlist",
      href: "/buildlist",
    },
    {
      id: "myGarage",
      primary: "My",
      secondary: "Garage",
      icon: "garage",
      customOnClick: (dispatch) => {
        dispatch({
          type: "setDrawerState",
          payload: {
            drawerState: {
              isDrawerOpen: false,
            },
          },
        });
        dispatch({
          type: "setCustomerProjectDrawerState",
          payload: {
            garageState: {
              isCustomerProjectDrawerOpen: true,
            },
          },
        });
      },
    },
    {
      id: "myCart",
      primary: "My",
      secondary: "Cart",
      icon: "cart",
      href: "/shoppingcart",
    },
    {
      id: "myAccount",
      primary: "My",
      secondary: "Account",
      icon: "user",
      href: "/account",
    },

    {
      id: "helpCenter",
      primary: "Speedway Motors",
      secondary: "Help Center",
      icon: "help",
      href: "/info/helpcenter",
    },
  ];

  return {
    showCallNow: true,
    navigation,
  };
};

export { getMainMenu };
