import {useEffect} from "react";

import {useRecommendationStore} from "../RecommendationStore";
import {useUserStore} from "Stores/UserStore/UserStore";
import {useVehicleStore} from "Stores/VehicleStore";
import {getRecommendationSections} from "Stores/RecommendationStore/Fetches/recommendations";
import {
    useUserSpecificProductDataStore
} from "../../../Contexts/UserSpecificProductDataStore/UserSpecificProductDataStore";

const RecommendationLoader = ({ set }) => {

    //REC STORE
    const inView = useRecommendationStore((x) => x.inView);
    const sections = useRecommendationStore((x) => x.sections);
    const isPageLoading = useRecommendationStore((x) => x.isPageLoading);
    const skuBaseNumbers = useRecommendationStore((x) => x.skuBaseNumbers);
    const skuVariantNumbers = useRecommendationStore((x) => x.skuVariantNumbers);
    const articleIds = useRecommendationStore((x) => x.articleIds);
    const keywords = useRecommendationStore((x) => x.keywords);
    const skusToNotDisplay = useRecommendationStore((x) => x.skusToNotDisplay);

    //USER STORE
    const isBot = useUserStore((x) => x.context.isBot);
    const userVinGet = useUserStore((x) => x.context.userVin);
    const getGuid = useUserStore((x) => x.context.getGuid);
    const userStateHydrated = useUserStore((x) => x.context.hydrated);

    //VEHICLE STORE
    const vehicleStoreHydrated = useVehicleStore((x) => x.context.hydrated);
    const selectedCustomerProject = useVehicleStore((x) => x.context.selectedCustomerProject);
    const customerProjects = useVehicleStore((x) => x.context.customerProjects);

    //USER SPECIFIC PRODUCT DATA
    const addTrackedSkuBases = useUserSpecificProductDataStore((x) => x.addTrackedSkuBases);

    useEffect(() => {
        try {
            const isReady =
                userStateHydrated && vehicleStoreHydrated && selectedCustomerProject?.hydrated;

            if (isReady && inView && !isBot()) {
                const userVin = userVinGet();
                const webUserGuid = getGuid();
                // WIFR-32634 I would like to update this variables naming convention when given the chance
                const ymmData = getYmmData(selectedCustomerProject, customerProjects);

                if (!isPageLoading) {
                    getRecommendationSections(
                        sections,
                        skuBaseNumbers,
                        skuVariantNumbers,
                        articleIds,
                        userVin,
                        webUserGuid,
                        ymmData,
                        keywords
                    )
                        .then((data) => {
                            if (data && skusToNotDisplay) {
                                data.recommendationSections[0].recommendationGroups.forEach(
                                    (group) => {
                                        group.recommendations = group.recommendations.filter(
                                            (recommendation) =>
                                                !(
                                                    skusToNotDisplay.includes(recommendation.skuBase) ||
                                                    skusToNotDisplay.includes(recommendation.skuVariant)
                                                )
                                        );
                                    }
                                );
                            }
                            beginLoadingVehicleAndShippingDataForRecommendations(data);

                            set((state) => {
                                return {
                                    ...state,
                                    recommendationData: data,
                                }
                            })
                        })
                        .then(() => {
                            set((state) => {
                                return {
                                    ...state,
                                    isLoading: false,
                                }
                            });
                        });
                }
            }
        } catch (e) {
            console.error(e);
        }

    }, [inView,
        isPageLoading,
        userStateHydrated,
        vehicleStoreHydrated,
        selectedCustomerProject?.hydrated]);


    function beginLoadingVehicleAndShippingDataForRecommendations(recommendationData) {

        const skuBasesToAdd = []
        for (let recommendationSection of recommendationData.recommendationSections) {
            for (let recommendationGroup of recommendationSection.recommendationGroups) {
                if (
                    recommendationGroup.getShippingEstimate ||
                    recommendationGroup.checkFitment
                ) {
                    for (let recommendation of recommendationGroup.recommendations) {
                        if (recommendation.skuBase || recommendation.skuBaseNumber) {
                            let skuBase = recommendation.skuBase ?? recommendation.skuBaseNumber;
                            skuBasesToAdd.push(skuBase);
                        }
                    }
                }
            }
        }
        if (skuBasesToAdd.length) {
            addTrackedSkuBases(skuBasesToAdd);
        }
    }
}

export function getYmmData(selectedCustomerProject, customerProjects) {

    if (selectedCustomerProject?.projectId) {
        const customerProject = selectedCustomerProject;
        return {
            isSelected: true,
            year: customerProject.yearId,
            make: customerProject.makeId,
            makeDisplay: customerProject.makeDisplay,
            model: customerProject.modelId,
            modelDisplay: customerProject.modelDisplay,
            raceTypeId: customerProject.raceTypeId,
            raceDisplay: customerProject.raceDisplay,
            vehicleBaseId: customerProject.vehicleBaseId,
        }
    }
    if (customerProjects?.length > 0) {
        const customerProject = customerProjects[0];
        return {
            isSelected: false,
            year: customerProject.year,
            make: customerProject.makeId,
            makeDisplay: customerProject.make,
            model: customerProject.modelId,
            modelDisplay: customerProject.model,
            raceTypeId: customerProject.raceTypeId,
            raceDisplay: customerProject.raceName,
            vehicleBaseId: customerProject.vehicleBaseId,
            // projectId: customerProject.projectId,
            // engineManufacturerId: customerProject.engineManufacturerId,
            // engineManufacturerName: customerProject.engineManufacturerName,
            // engineMakeId: customerProject.engineMakeId,
            // engineMakeName: customerProject.engineMakeName,
            // engineSeriesId: customerProject.engineSeriesId,
            // engineSeriesName: customerProject.engineSeriesName,
            // engineConfigurationId: customerProject.engineConfigurationId,
            // engineConfigurationName: customerProject.engineConfigurationName,
            // engineDisplacementId: customerProject.engineDisplacementId,
            // engineDisplacementName: customerProject.engineDisplacementName,
            // engineDisplayName: customerProject.engineDisplayName,
        }
    }

    return null;
}

export default RecommendationLoader;