import React, { useEffect, useState, useRef } from "react";
import { useInView } from "react-intersection-observer";
import ClassNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";

import useWindow from "Hooks/useWindow";
import PaginationControl from "Atoms/PaginationControl";
import LayerColorFinder from "Utilities/LayerColorFinder";
import CarouselItem from "./CarouselItem";
import Panel from "Atoms/Panel"

import { Slide, GetFirstOutOfBoundsItem } from "./slide";
import LoopToOtherSide from "./infiniteScroll";
import styles from "./Carousel.module.scss";

const propTypes = {
  className: PropTypes.string,
  leftArrowOnClick: PropTypes.func,
  isStaticPaginationBackground: PropTypes.bool,
  rightArrowOnClick: PropTypes.func,
  layer: PropTypes.number,
};

const Carousel = ({
  className,
  infiniteScroll,
  leftArrowOnClick,
  rightArrowOnClick,
  isStaticPaginationBackground,
  isTransparentBackground = false,
  children,
  layer,
  lazyBoundaryRef,
  leftAlignCarousel,
  showGradient,
  extendGutters,
  inverseTheme,
  ...props
}) => {
  layer = layer || 0;

  const [leftCarouselEdgeRef, leftCarouselEdgeInView] = useInView({
    initialInView: true,
  });
  const [rightCarouselEdgeRef, rightCarouselEdgeInView] = useInView({
    initialInView: true,
  });
  const [startOfPatternIntersectionRef, startOfPatternInView] = useInView({
    initialInView: true,
  });
  const [endOfPatternIntersectionRef, endOfPatternInView] = useInView();
  const carouselWindowRef = useRef();
  const leftGradientRef = useRef();
  const startOfPatternRef = useRef();
  const endOfPatternRef = useRef();
  lazyBoundaryRef = lazyBoundaryRef ?? carouselWindowRef;

  const [isInfiniteScroll, setIsInfiniteScroll] = useState(infiniteScroll);
  const [numberOfLoopsToTheRight, setNumberOfLoopsToTheRight] = useState(0);
  const [maxNumberOfVisibleItems, setMaxNumberOfVisibleItems] = useState(0);

  const carouselClass = extendGutters
    ? styles.carouselExtendGutters
    : styles.carousel;
  const carouselInnerClass = extendGutters
    ? styles.carouselInnerExtendGutters
    : styles.carouselInner;

  const classes = ClassNames(
    carouselClass,
    className,
    styles.test,
    children && !children[0] && "hide"
  );
  const baseColor = LayerColorFinder(layer);

  const rawGradientColor = LayerColorFinder(layer);
  const hue = rawGradientColor.split(",")[0].replace("hsla(", "");
  const saturation = rawGradientColor.split(",")[1];
  const lightness = rawGradientColor.split(",")[2];
  const gradientColor = `hsla(${hue},${saturation},${lightness},0)`;

  function determineHiddenStyling(leftAlignCarousel, leftCarouselEdgeInView) {
    if (leftCarouselEdgeInView) {
      if (leftAlignCarousel && styles.hidden_left) {
        return styles.hidden_left;
      } else if (styles.hidden) {
        return styles.hidden;
      }
    } else {
      return "";
    }
  }

  useEffect(() => {
    if (isInfiniteScroll) {
      LoopToOtherSide(
        "right",
        startOfPatternRef,
        endOfPatternRef,
        lazyBoundaryRef,
        numberOfLoopsToTheRight,
        setNumberOfLoopsToTheRight
      );
    }
  }, [endOfPatternInView]);

  useEffect(() => {
    if (isInfiniteScroll) {
      LoopToOtherSide(
        "left",
        startOfPatternRef,
        endOfPatternRef,
        lazyBoundaryRef,
        numberOfLoopsToTheRight,
        setNumberOfLoopsToTheRight
      );
    }
  }, [startOfPatternInView]);

  useEffect(() => {
    setIsInfiniteScroll(infiniteScroll);
    if (infiniteScroll) {
      getMaxNumberOfVisibleItems();
    } else {
      setIsInfiniteScroll(false);
      setMaxNumberOfVisibleItems(0);
    }
  }, [infiniteScroll]);

  useEffect(() => {
    getMaxNumberOfVisibleItems();
  }, []);

  const getMaxNumberOfVisibleItems = () => {
    if (!infiniteScroll) {
      return;
    }

    const carouselWindow = lazyBoundaryRef.current;
    if (
      !carouselWindow?.childNodes[0]?.childNodes?.length ||
      !carouselWindow?.parentElement?.childNodes?.length
    ) {
      console.error("Could not get the items of the carousel.");
      setIsInfiniteScroll(false);
      setMaxNumberOfVisibleItems(0);
      return;
    }

    const carouselChildren = Array.from(
      carouselWindow.childNodes[0].childNodes
    );
    const carouselItems = carouselChildren.filter(
      (x) => !x.getAttribute("data-nonitem")
    );
    const leftGradient = carouselWindow.parentElement.childNodes[0];
    const rightGradient = carouselWindow.parentElement.childNodes[2];
    const gradientTransparentOffset = 20;
    // Now discover the index of the last one that is visible, the same way slide does it to pick which item to scroll to.
    // That index + 1 is equal to the number of visible items initially. We instead do + 2 just to be safe.
    const rightOutOfBoundsItem = GetFirstOutOfBoundsItem(
      "right",
      carouselItems,
      leftGradient,
      rightGradient,
      gradientTransparentOffset
    );
    const leftOutOfBoundsItem = GetFirstOutOfBoundsItem(
      "left",
      carouselItems,
      leftGradient,
      rightGradient,
      gradientTransparentOffset
    );
    const calculatedMaxNumberOfVisibleItems =
      rightOutOfBoundsItem + 2 - leftOutOfBoundsItem;
    const windowShowsAllItems =
      calculatedMaxNumberOfVisibleItems - 1 >= children.length;

    if (!windowShowsAllItems) {
      setIsInfiniteScroll(true);
      setMaxNumberOfVisibleItems(calculatedMaxNumberOfVisibleItems);
    } else {
      setIsInfiniteScroll(false);
      setMaxNumberOfVisibleItems(0);
    }
  };

  useWindow((window) => {
    window.addEventListener("resize", getMaxNumberOfVisibleItems);
  });


  return (
    <>
      <Panel className={classes} layer={layer} inverseTheme={inverseTheme} transparent={isTransparentBackground} {...props}>
        <PaginationControl
          className={`${determineHiddenStyling(
            leftAlignCarousel,
            leftCarouselEdgeInView
          )}`}
          direction={"left"}
          onClick={(e) => {
            Slide(
              e,
              startOfPatternRef.current,
              endOfPatternRef.current,
              numberOfLoopsToTheRight,
              setNumberOfLoopsToTheRight,
              isInfiniteScroll,
              "left"
            );
            typeof leftArrowOnClick === "function" ? leftArrowOnClick() : false;
          }}
          brand={"secondarypalette_purple"}
          aria-label={"Carousel Left Button"}
          tabIndex={"-1"}
          isStaticBackground={isStaticPaginationBackground}
          data-testid={props.id ? `${props.id}-left` : undefined}
          inverseTheme={inverseTheme}
        />
        <div className={styles.carouselWindow}>
          <StyledLeftGradient
            ref={leftGradientRef}
            className={
              leftCarouselEdgeInView
                ? ClassNames(
                    styles.gradient,
                    styles.left_gradient,
                    styles.hidden
                  )
                : ClassNames(styles.gradient, styles.left_gradient)
            }
            color={baseColor}
            gradientColor={gradientColor}
            showGradient={showGradient}
          ></StyledLeftGradient>
          <div className={styles.carouselWindow} ref={lazyBoundaryRef}>
            <div className={carouselInnerClass} tabIndex={0}>
              <div
                data-nonitem={true}
                ref={leftCarouselEdgeRef}
                className={styles.ref_div}
              ></div>
              {children.slice(0, maxNumberOfVisibleItems)}
              <div
                data-nonitem={true}
                ref={startOfPatternIntersectionRef}
              ></div>
              <div data-nonitem={true} ref={startOfPatternRef}></div>
              {children.slice(maxNumberOfVisibleItems)}
              {children.slice(0, maxNumberOfVisibleItems)}
              <div data-nonitem={true} ref={endOfPatternRef}></div>
              <div
                data-nonitem={true}
                ref={endOfPatternIntersectionRef}
                className={styles.ref_div}
              ></div>
              {children.slice(
                maxNumberOfVisibleItems,
                maxNumberOfVisibleItems * 2
              )}
              {children.slice(
                0,
                maxNumberOfVisibleItems * 2 > children.length
                  ? maxNumberOfVisibleItems * 2 - children.length
                  : 0
              )}
              <div
                data-nonitem={true}
                ref={rightCarouselEdgeRef}
                className={styles.ref_div}
              ></div>
            </div>
          </div>
          <StyledRightGradient
            className={
              rightCarouselEdgeInView
                ? ClassNames(
                    styles.gradient,
                    styles.right_gradient,
                    styles.hidden
                  )
                : ClassNames(styles.gradient, styles.right_gradient)
            }
            color={baseColor}
            gradientColor={gradientColor}
            showGradient={showGradient}
          ></StyledRightGradient>
        </div>
        <PaginationControl
          className={rightCarouselEdgeInView ? ClassNames(styles.hidden) : ''}
          direction={"right"}
          onClick={(e) => {
            Slide(
              e,
              startOfPatternRef.current,
              endOfPatternRef.current,
              numberOfLoopsToTheRight,
              setNumberOfLoopsToTheRight,
              isInfiniteScroll,
              "right"
            );
            typeof rightArrowOnClick === "function"
              ? rightArrowOnClick()
              : false;
          }}
          brand={"secondarypalette_purple"}
          aria-label={"Carousel Right Button"}
          tabIndex={"-1"}
          isStaticBackground={isStaticPaginationBackground}
          data-testid={props.id ? `${props.id}-right` : undefined}
          inverseTheme={inverseTheme}
        />
      </Panel>
    </>
  );
};

const StyledLeftGradient = styled.div`
  ${(props) => {
    if (props.showGradient)
      return `background-image: linear-gradient(-90deg, ${props.gradientColor} 0%, ${props.color} 80%);`;
  }}
`;
const StyledRightGradient = styled.div`
  ${(props) => {
    if (props.showGradient)
      return `background-image: linear-gradient(90deg, ${props.gradientColor} 0%, ${props.color} 80%);`;
  }}
`;

Carousel.displayName = "Carousel";

Carousel.Item = CarouselItem;

Carousel.propTypes = propTypes;

export default Carousel;
