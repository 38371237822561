import React, {Fragment, useCallback, useContext, useEffect, useState} from "react";

import Panel from "Atoms/Panel";
import Svg from "Atoms/Svg";
import Typography from "Atoms/Typography";
import Modal from "Components/Modal";
import PanelContext from "Contexts/PanelContext";
import {TrackingContext} from "Contexts/TrackingContext/TrackingContext";
import useKeypress, {KEYCODES} from "Hooks/useKepress";
import useWindow from "Hooks/useWindow";
import {updateRecentSearches} from "Layout/Header/RecentSearches/RecentSearches";
import SearchBar from "Molecules/SearchBar";
import ClassNames from "classnames";
import {get} from "idb-keyval";

import useSegment from "../../../Hooks/useSegment";
import {useVehicleStore} from "../../../Stores/VehicleStore";
import {GetUserGuid, GetUserVin,} from "../../../Utilities/CookieUtilties/UserCookieUtilities";
import styles from "./SaytDropdown.module.scss";
import SaytInitialContent from "./SaytInitialContent/SaytInitialContent";
import SaytItems from "./SaytItems";
import {buildNewSearchUrl} from "./SaytUtilities/setNewSearchUrl";
import SuggestedSearches from "./SuggestedSearches";
import YmmButton from "../../../Components/YmmButton/YmmButton";


const recentSearchesKey = "RecentSearches";
const SaytDropdown = ({
  className,
  mobilePlaceholder,
  desktopPlaceholder,
  services,
  setIsSaytModalOpen,
  isSaytModalOpen,
  ymmOrRaceTypeValue,
  isEditing,
  ...props
}) => {
  const { sendSegmentTrackEventWithName } = useSegment();
  const { segmentReady } = useContext(TrackingContext);
  const [searchTerm, setSearchTerm] = useState("");

  const [saytResults, setSaytResults] = useState(false);
  const selectedCustomerProject = useVehicleStore((x) => x.context.selectedCustomerProject);
  const getSelectedCustomerProjectTitle = useVehicleStore((x) => x.getSelectedCustomerProjectTitle);
  const fakePanelContext = {
    brand: undefined,
    intent: undefined,
    inverseTheme: true,
    layer: 0,
  };
  const [currentUrl, setCurrentUrl] = useState("");
  const [initSearchTerm, setInitSearchTerm] = useState("");
  useWindow(() => {
    setCurrentUrl(window.location.href.toLowerCase());
  });

  const fetchRecentSearches = useCallback(async () => {
    if (!isSaytModalOpen || !currentUrl.includes("/search")) {
      return;
    }

    try {
      const recentSearchesFromIndexDB = await get(recentSearchesKey);

      if (recentSearchesFromIndexDB?.length) {
        setInitSearchTerm(recentSearchesFromIndexDB[0]);
        setSearchTerm(recentSearchesFromIndexDB[0]);
      }
    } catch (err) {
      console.error("Error fetching recent searches:", err);
    }
  }, [isSaytModalOpen, currentUrl, recentSearchesKey]);

  useEffect(() => {
    let ignore = false;

    if (isSaytModalOpen && !isEditing) {
      setInitSearchTerm('');
      setSearchTerm('');
    } else {
      fetchRecentSearches().catch(error => {
        console.error("Fetch recent searches failed", error);
      });
    }

    return () => {
      ignore = true;
    };
  }, [isSaytModalOpen, currentUrl, isEditing, fetchRecentSearches]);

  useEffect(() => {
    if (!searchTerm || searchTerm === initSearchTerm) {
      setSaytResults(false);
      return;
    }

    let cleanSearch = searchTerm.trim();
    if (cleanSearch !== "") {
      getSaytResults(
        searchTerm,
        services?.discovery?.url,
        GetUserGuid(),
        GetUserVin(),
        selectedCustomerProject?.yearId,
        selectedCustomerProject?.makeId,
        selectedCustomerProject?.modelId,
        selectedCustomerProject?.raceTypeId
      ).then((saytResults) => {
        if (saytResults) setSaytResults(saytResults);
      });
    }
  }, [searchTerm]); //eslint-disable-line react-hooks/exhaustive-deps

  useKeypress(KEYCODES.escape, () => {
    setSearchTerm(initSearchTerm);
    setIsSaytModalOpen(false);
  });

  if (!isSaytModalOpen) {
    return false;
  }

  const vehicleTitle = getSelectedCustomerProjectTitle();

  const setNewSearchUrl = (searchTerm, searchUrl) => {
    if (segmentReady) {
      sendSegmentTrackEventWithName("Products Searched", {
        user_vin: `${GetUserVin()}`,
        web_user_guid: `${GetUserGuid()}`,
        query: searchTerm,
        page_type: window.location.href,
        element: "search_bar_header",
      });
    }
    updateRecentSearches(searchTerm);

    window.location = buildNewSearchUrl(searchTerm, searchUrl);
  };

  const requestSearchUrl = (event) => {
    event.preventDefault();
    if (!searchTerm.trim()) {
      CloseSaytModal();
      return;
    }
    setNewSearchUrl(searchTerm, selectedCustomerProject.customerProjectSearchUrl);
  };
  const clearSearchTerm = () => {
    setSearchTerm("");
  };
  const CloseSaytModal = () => {
    setSearchTerm(initSearchTerm);
    setIsSaytModalOpen(false);
  };

  let ymmButtonMobile = (
      <YmmButton
          ymmOrRaceTypeValue={ymmOrRaceTypeValue}
          isMobile={true}
      ></YmmButton>
  )

  var topOfModalContent = (
    <Fragment>
      <div className={styles.searchBarTitleWrapper} onClick={CloseSaytModal}>
        <PanelContext.Provider value={fakePanelContext}>
          <Typography
            className={styles.transparentHeader}
            size={1}
            font={"heading"}
          >
            Search Speedway Motors
          </Typography>
        </PanelContext.Provider>
        <Panel className={styles.modalClose}>
          <Svg size={0.75} icon={"close"} data-testid={"modalCloseButton"} />
        </Panel>
      </div>
      <SearchBar
        iconSettings={{ size: 1.25, position: "end", filled: false }}
        className={ClassNames(className, styles.saytSearch, styles.hideMobile)}
        onChange={(s) => setSearchTerm(s)}
        handleSearch={(e) => requestSearchUrl(e)}
        clearSearchTerm={() => clearSearchTerm()}
        autofocus={true}
        value={searchTerm}
        textAhead={saytResults?.typeAheadSuggestion ?? null}
        isSaytModalOpen={isSaytModalOpen}
        placeholder={desktopPlaceholder}
        ymmOrRaceTypeValue={vehicleTitle}
        dataTesting={"saytSearchDesktop"}
        dataTestingSearchSubmit={"saytSearchDesktopSubmit"}
        isMobile={false}
      />

      {/* This addition below is only included because placeholder ellipsis are not able to function properly. Need a shrunken text... */}
      <SearchBar
        iconSettings={{ size: 1.25, position: "end", filled: false }}
        className={ClassNames(className, styles.saytSearch, styles.hideDesktop)}
        onChange={(e) => {
          setSearchTerm(e);
        }}
        handleSearch={(e) => requestSearchUrl(e)}
        clearSearchTerm={() => clearSearchTerm()}
        isSaytModalOpen={isSaytModalOpen}
        autofocus={true}
        textAhead={saytResults?.typeAheadSuggestion}
        value={searchTerm}
        placeholder={mobilePlaceholder}
        dataTesting={mobilePlaceholder}
        dataTestingSearchSubmit={"saytSearchMobileSubmit"}
        ymmOrRaceTypeValue={vehicleTitle}
        isMobile={true}
      />

      {ymmButtonMobile}

      {!saytResults ? (
        <>
          <div>
            <SaytInitialContent />
          </div>
        </>
      ) : (
          false
      )}
    </Fragment>
  );

  let saytThings = "";

  if (
    searchTerm &&
    saytResults &&
    (saytResults.autoSuggestProducts?.length > 0 ||
      saytResults.autoSuggestArticles?.length > 0 ||
      saytResults.suggestedQueries?.length > 0)
  ) {
    saytThings = (
      <Panel
        className={ClassNames(
          styles.saytWrapper,
          saytResults.suggestedQueries?.length ? null : styles.hideMobile
        )}
      >
        {saytResults?.suggestedQueries?.length ? (
          <div className={styles.suggestedSearchWrapper}>
            <SuggestedSearches
              searchTerm={searchTerm}
              saytResults={saytResults}
              searchUrl={selectedCustomerProject.customerProjectSearchUrl}
            />
          </div>
        ) : (
          false
        )}
        <div className={styles.saytSecondColumnWrapper}>
          <SaytItems
            saytItems={saytResults?.autoSuggestProducts}
            type={"PRODUCT"}
          />
          {saytResults?.autoSuggestArticles &&
          saytResults.autoSuggestArticles.length > 0 ? (
            <SaytItems
              saytItems={saytResults?.autoSuggestArticles}
              type={"ARTICLE"}
              maxItemsToDisplay={3}
            />
          ) : null}
        </div>
      </Panel>
    );
  }

  try {
    return (
      <div>
        <Modal
          toggleOpen={(isSaytModalOpen) => {
            setSearchTerm(initSearchTerm);
            setIsSaytModalOpen(isSaytModalOpen);
          }}
          open={isSaytModalOpen}
          allowOutsideClickClose
          contentAboveModal={topOfModalContent}
          alignItems={"flex-start"}
          className={styles.saytContainer}
          id={"sayt_container"}
        >
          {saytThings}
        </Modal>
      </div>
    );
  } catch (e) {
    console.error(e);
    return false;
  }
};

function getSaytResults(
  searchTerm,
  discoveryUrl,
  webUserGuid,
  userVin,
  year,
  makeId,
  modelId,
  raceTypeId
) {
  const query = `query GetAutoSuggestPublicResults($autoSuggestRequest: AutoSuggestPublicRequestInput) {
        autoSuggestPublic(autoSuggestPublicRequest: $autoSuggestRequest) {
          autoSuggestProducts {
                  position
                  price
                  url
                  skuBaseNumber
                  title
                  thumbImageUrl
                  publicSkuBaseDetails {
                    availability
                    reviewCount
                    rating
                    segmentCategorization
                    superMarketAffinity
                    brand
                  }
          }
          autoSuggestArticles{
              position
              url
              title
              thumbImageUrl
          }
          categoryLinks{
            url
            name
          }
          typeAheadSuggestion
          productQuery
              suggestedQueries {
                ordinality
                query,
                queryWithMarkups
              }
        }
      }`;

  let variables = {
    autoSuggestRequest: {
      query: searchTerm,
      numberOfSuggestions: 12,
      currentUrl: window.location.href,
      userVin,
      webUserGuid,
      ymmBoost: {
        year: year,
        make: makeId,
        model: modelId,
        raceTypeId: raceTypeId,
      },
    },
  };

  return requestSaytData(query, variables, discoveryUrl).then((result) => {
    return result?.data?.autoSuggestPublic;
  });
}

// this needs to be in an outer scope so that it's shared across requests
let abortController = null;

async function requestSaytData(query, variables, discoveryUrl) {
  try {
    let signal = null;
    if (window && window.AbortController) {
      if (abortController) {
        abortController.abort();
      }
      abortController = new AbortController();
      signal = abortController.signal;
    }
    const response = await fetch(discoveryUrl, {
      signal: signal,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: query,
        variables: variables,
      }),
      credentials: "include",
    });

    var data = await response.json();

    if (data.errors) {
      data.errors.forEach((x) => console.error(x));
    }

    return data;
  } catch (error) {
    if (error.name === "AbortError") {
      return [];
    }

    console.error("Failed Sayt Request", error);
    return [];
  }
}

export default SaytDropdown;
