import React, { Fragment, useState } from 'react'

import useFacebook from 'Hooks/useFacebook'

import {Default, Selected, Disabled} from 'Molecules/Button/Variants/OptionButtons'

import styles from './indexoptionButtons.module.scss'
import { createClickedSegmentEvent } from '../../../Utilities/Instrumentation/Impressions/impressionSegmentEvents'

const IndexOptionButtons = ({
  indexOptionValues,
  selectIndexOption, 
  productPageId, 
  selectedIndexOptions, 
  optionIndexForTesting,
  isIndexOptionErrorState
}) => {
  if(!indexOptionValues)
    return false
  return (
    <Fragment>
      {indexOptionValues
        .sort((a, b) => {
          return (parseInt(a.ordinality) || a.displayName) - (parseInt(b.ordinality) || b.displayName);
        })
        .map((value, i) => {
          if(value.isSelected){
            return (
              <SelectedButton 
                key={'selected-btn'+value.id}
                value={value}
                optionIndexForTesting={optionIndexForTesting}
                valueIndexForTesting={i}
                productPageId={productPageId}
                classNames={styles.indexOptionButton}/>
            )
          }else if (value.isDisabled || !value.isPurchaseable){
            return (
              <DisabledButton 
                key={'disabled-btn'+value.id}
                value={value} 
                selectIndexOption={selectIndexOption} 
                productPageId={productPageId}
                selectedIndexOptions={selectedIndexOptions}
                optionIndexForTesting={optionIndexForTesting}
                valueIndexForTesting={i}
                classNames={styles.indexOptionButton}/>
            )
          } else{
            return (
              <Button 
                key={'btn'+value.id}
                value={value} 
                selectIndexOption={selectIndexOption} 
                productPageId={productPageId} 
                selectedIndexOptions={selectedIndexOptions} 
                optionIndexForTesting={optionIndexForTesting}
                valueIndexForTesting={i}
                isIndexOptionErrorState={isIndexOptionErrorState && !indexOptionValues.some(value => value.isSelected)}
                classNames={styles.indexOptionButton}
                segmentEvent={createClickedSegmentEvent('IndexOption')}/>
            )}
          }
      )}
    </Fragment>
  )
}

const Button = ({
  value, 
  selectIndexOption, 
  productPageId, 
  selectedIndexOptions, 
  optionIndexForTesting,
  valueIndexForTesting,
  isIndexOptionErrorState,
  classNames}) => {

  const sendFacebookEvent = useFacebook()
  const [waiting, setWaiting] = useState(false)

  return (
    <Default
      onClick={(e) => {
        if (!value.url) {
          e.preventDefault()
          setWaiting(true)
          selectIndexOption(productPageId, null, selectedIndexOptions, value.id, sendFacebookEvent)
        }else{
          setWaiting(true)
        }
      }}
      value={value}
      isWaiting={waiting}
      key={`${productPageId}:option_${optionIndexForTesting}-value_${valueIndexForTesting}-selected_false`}
      testid={`${productPageId}:option_${optionIndexForTesting}-value_${valueIndexForTesting}`} 
      errorState={isIndexOptionErrorState}
      classNames={classNames}
    />
  )
}

const SelectedButton = ({value, optionIndexForTesting, valueIndexForTesting, productPageId, classNames}) => {

  return (
    <Selected
      value={value}
      key={`${productPageId}:option_${optionIndexForTesting}-value_${valueIndexForTesting}-selected_true`}
      testid={`${productPageId}:option_${optionIndexForTesting}-value_${valueIndexForTesting}`}
      brand={'secondarypalette_purple'}
      classNames={classNames}
    />
  )
}

const DisabledButton = ({
  value,
  selectIndexOption, 
  productPageId, 
  selectedIndexOptions,
  optionIndexForTesting,
  valueIndexForTesting,
  classNames}) => {


  const sendFacebookEvent = useFacebook()

  return (
    <Disabled
      onClick={() => selectIndexOption(productPageId, null, selectedIndexOptions, value.id, sendFacebookEvent)}
      value={value}
      key={`${productPageId}:option_${optionIndexForTesting}-value_${valueIndexForTesting}_disabled`}    
      testid={`${productPageId}:option_${optionIndexForTesting}-value_${valueIndexForTesting}`}
      classNames={classNames}
    />
  )
}




export default IndexOptionButtons