import { useState } from "react";

import useFeatureFlag from "Hooks/useFeatureFlag";
import useLockedAsyncEffect from "Hooks/useLockedAsyncEffect";
import Retry from "Utilities/Retry";

import { useUserStore } from "../../../Stores/UserStore/UserStore";
import { useUserSpecificProductDataStore } from "../UserSpecificProductDataStore";
import { validateZip } from "Utilities/Location/Location"


export default function LazyLoadShippingData({ disable }) {
  const [previousZipCode, setPreviousZipCode] = useState(null);

  const trackedSkuBases = useUserSpecificProductDataStore(
    (x) => x.trackedSkuBases
  );
  const trackedSkuVariants = useUserSpecificProductDataStore(
    (x) => x.trackedSkuVariants
  );

  const shippingDataBySkuBasesOrVariants = useUserSpecificProductDataStore(
    (x) => x.shippingDataBySkuBasesOrVariants
  );
  const addShippingDataForSkuBasesOrVariants = useUserSpecificProductDataStore(
    (x) => x.addShippingDataForSkuBasesOrVariants
  );

  const updateUserZipCode = useUserStore(
    (x) => x.updateUserZipCode
  )

  const shippingEstimateFlag = useFeatureFlag("PRP_FrontEnd_ShippingEstimates");
  const zipCode = useUserStore((x) => x.context.zipCode);

  async function loadProductShippingData() {
    if (!shippingEstimateFlag) {
      return;
    }

    if (disable) {
      return;
    }

    var [zip, isValidZip] = validateZip(zipCode);

    if (!isValidZip) {
      return;
    }

    if (zip != zipCode) {
      updateUserZipCode(zip)
    }

    let skuBasesToRequest = [];
    let skuVariantsToRequest = [];

    // If the zip has changed, request shipping data for all tracked skuBases and variants
    if (zip !== previousZipCode) {
      skuBasesToRequest = trackedSkuBases ?? [];
      skuVariantsToRequest = trackedSkuVariants ?? [];
    } else {
      skuBasesToRequest = trackedSkuBases.filter(
        (skuBase) => !shippingDataBySkuBasesOrVariants[skuBase]
      ) ?? [];
      skuVariantsToRequest = trackedSkuVariants.filter(
        (skuVariant) => !shippingDataBySkuBasesOrVariants[skuVariant]
      ) ?? [];
    }

    if (skuBasesToRequest.length || skuVariantsToRequest.length) {
      const module = await Retry(() =>
        import("Utilities/shippingEstimates/shippingEstimates")
      );
      const getShippingEstimates = module.default;
      const shippingData = await getShippingEstimates(
        skuVariantsToRequest,
        skuBasesToRequest,
        zip
      );

      shippingData.forEach((shipping) => {
        if (skuVariantsToRequest.includes(shipping.skuVariantUsed)) {
          addShippingDataForSkuBasesOrVariants(shipping.skuVariantUsed, shipping);
        } else {
          addShippingDataForSkuBasesOrVariants(shipping.skuBase, shipping);
        }
      });
    }
    setPreviousZipCode(zip); // to detect when to full reload all data
  }

  useLockedAsyncEffect(async () => {
    await loadProductShippingData();
  }, [shippingEstimateFlag, disable, trackedSkuBases, trackedSkuVariants, zipCode]);

  return false;
}
