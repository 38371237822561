const getMatchingProject = (projectToFind, customerProjects) => {
    if(!projectToFind || !customerProjects || customerProjects.length < 1)
        return null

    const existingProject = customerProjects?.find(
        (project) => 
          equals(project.year, projectToFind?.year) &&
          equals(project.makeId, projectToFind?.makeId) &&
          equals(project.modelId, projectToFind?.modelId) &&
          equals(project.raceTypeId, projectToFind?.raceTypeId) &&
          equals(project.engineManufacturerId, projectToFind?.engineManufacturerId) &&
          equals(project.engineMakeId, projectToFind?.engineMakeId) &&
          equals(project.engineSeriesId, projectToFind?.engineSeriesId) &&
          equals(project.engineConfigurationId, projectToFind?.engineConfigurationId) &&
          equals(project.engineQualifierId, projectToFind?.engineQualifierId) &&               
          equals(project.engineVersionId, projectToFind?.engineVersionId) &&
          equals(project.engineDisplacementId, projectToFind?.engineDisplacementId) &&
          equals(project.engineCodeId, projectToFind?.engineCodeId) &&
          equals(project.engineCamshaftTypeId, projectToFind?.engineCamshaftTypeId) &&
          equals(project.engineFuelSystemId, projectToFind?.engineFuelSystemId)
    )

    return existingProject
}

const equals = (a, b) => {
    if (a == b)
        return true
    if (a === 1 && !b)
        return true
    if (b === 1 && !a) 
        return true
    return false
}

export default getMatchingProject