import React, { Fragment } from "react";

import InlineSpinner from "Atoms/InlineSpinner";
import Typography from "Atoms/Typography";
import PanelContext from "Contexts/PanelContext";
import LayerColorFinder from "Utilities/LayerColorFinder";
import ClassNames from "classnames";
import styled from "styled-components";

import styles from "./OptionButton.module.scss";

const Disabled = ({
  onClick,
  value,
  svg,
  testid,
  classNames = "",
  ...props
}) => {
  const borderColor = LayerColorFinder(3);
  const hoverColor = LayerColorFinder(0);

  return (
    <Fragment>
      <StyledButton
        onClick={onClick}
        className={ClassNames(classNames, styles.optionButton, styles.disabled)}
        borderColor={borderColor}
        hoverColor={hoverColor}
        backgroundColor={"transparent"}
        borderStyle={"dashed"}
        data-testid={testid}
        data-is-disabled={true}
        {...props}
      >
        {value?.displayName ? (
          <Typography font={"bold"} tone={"superfluous"}>
            {value.displayName}
          </Typography>
        ) : (
          svg
        )}
      </StyledButton>
    </Fragment>
  );
};

const Default = ({
  value,
  svg,
  isWaiting,
  testid,
  onClick,
  classNames = "",
  isErrorState,
  ...props
}) => {
  const borderColor = !isErrorState
    ? LayerColorFinder(3)
    : LayerColorFinder(null, null, null, "negative");
  const hoverColor = LayerColorFinder(1);
  const backgroundColor = LayerColorFinder(null, null, null, null);

  
  const component =  value?.url ? "a" : "button";


  return (
    <>
      <StyledButton
        as={component}
        {...(value?.url ? { href: value?.url } : {})}
        onClick={onClick}
        className={
          isWaiting
            ? ClassNames(classNames, styles.optionButton, styles.buttonWaiting, value?.url ? "url_index_option" : null)
            : ClassNames(classNames, styles.optionButton, value?.url ? "url_index_option" : null)
        }
        borderColor={borderColor}
        hoverColor={hoverColor}
        backgroundColor={backgroundColor}
        borderStyle={"solid"}
        data-testid={testid}
        {...props}
      >
        {isWaiting ? (
          <>
            <div className={styles.spinnerWrapper}>
              <InlineSpinner
                brand={"primary"}
                size={0.5}
              />
            </div>
          </>
        ) : null}
        {value?.displayName ? (
          <Typography font={"bold"} tone={"contrast"} as={"span"}>
            {value.displayName}
          </Typography>
        ) : (
          svg
        )}
      </StyledButton>
    </>
  );
};

const Selected = ({
  value,
  svg,
  testid,
  classNames,
  intent,
  brand,
  ...props
}) => {
  if (!brand && !intent) return false;

  const borderColor = LayerColorFinder(null, null, brand, intent);
  const hoverColor = LayerColorFinder(null, null, brand, intent);
  const backgroundColor = LayerColorFinder(null, null, brand, intent);

  const panelContextValue = {
    brand: brand,
    intent: intent,
    invert: null,
  };

  return (
    <Fragment>
      <StyledButton
        onClick={() => {}}
        className={ClassNames(
          classNames,
          styles.optionButton,
          `bg-color-${brand}`
        )}
        borderColor={borderColor}
        hoverColor={hoverColor}
        backgroundColor={backgroundColor}
        borderStyle={"solid"}
        data-testid={testid}
        data-is-selected={true}
        {...props}
      >
        <PanelContext.Provider value={panelContextValue}>
          {value?.displayName ? (
            <Typography font={"bold"} tone={"contrast"}>
              {value.displayName}
            </Typography>
          ) : (
            svg
          )}
        </PanelContext.Provider>
      </StyledButton>
    </Fragment>
  );
};

const StyledButton = styled.button`
  border: ${(props) => "1px " + props.borderStyle + " " + props.borderColor};
  background-color: ${(props) => props.backgroundColor};
  &:hover {
    background-color: ${(props) => props.hoverColor};
  }
`;

export { Default, Selected, Disabled };
