import React from 'react'
import ClassNames from 'classnames'
import TextLink from 'Atoms/TextLink'
import FeaturedArticle from '../FeaturedArticle'
import Typography from 'Atoms/Typography'
import List from 'Molecules/List'
import Image from 'next/image'
import Svg from 'Atoms/Svg'
import Skeleton from 'Atoms/Skeleton'
import Event from 'Utilities/Event'
import ArticleCard from 'Containers/ArticleCard'
import Button from 'Molecules/Button'
import styles from './Toolbox.module.scss'
import SkeletonContent from '../../../Containers/SkeletonContent'
import { createClickedSegmentEvent } from '../../../Utilities/Instrumentation/Impressions/impressionSegmentEvents'

const ToolboxBladeEvent = label => Event({
    category: 'Header',
    action: 'Toolbox Blade clicked',
    label,
})

const ToolboxContent = ({
    recommendedArticles,
    tech,
    builds,
    stories,
    featuredArticle
}) => {
    let featuredTitle = "Featured Article"
    return (
        <>
            <div className={styles.toolboxContent}>

                <div className={styles.mainContent}>                  
                  <div className={ClassNames(styles.toolboxFeaturedArticleSection, recommendedArticles?.length >= 1 ? styles.recommendationsEnabled : null)}>
                        <div className={styles.buttonContainer}>
                            <Button
                                brand={"secondary"}
                                segmentEvent={createClickedSegmentEvent('SeeAllArticles')}
                                size={"medium"}
                                text={"All Articles"}
                                href={'/the-toolbox'}
                                data-testid={"toolbox_blade_see_all_toolbox_articles"}
                                >
                                <Typography size={1} font={"bold"}>
                                    See All Toolbox Articles
                                </Typography>
                            </Button>  
                        </div>
                        <Typography
                            className={ClassNames(styles.toolBoxMenuTitle, styles.featuredArticleTitle)}
                            href={featuredArticle?.url}
                            onClick={() => ToolboxBladeEvent(featuredTitle)()}
                            size={1.25}
                            tone={'contrast'}
                            font={'heading'}
                            as={'a'}
                        >
                            {featuredTitle}
                        </Typography>

                        <div className={ClassNames(styles.featuredArticleWrapper, recommendedArticles?.length >= 1 ? styles.recommendationsEnabled : null)}>
                            <ArticleCard article={featuredArticle} useButton />
                        </div>
                    </div>
                    <div className={styles.menuSectionContainer}>
                        <ToolboxMenuSection title={'Tech'} options={tech} href={'/the-toolbox/tech~2-1'}/>
                        <ToolboxMenuSection title={'Builds'} options={builds} />
                        <ToolboxMenuSection title={'Stories'} options={stories} href={'/the-toolbox/news~2-3'} />
                    </div>
                    <div className={styles.mediaContentSection}>
                        <MediaContent
                            title={'Horsepower Recipes'}
                            content={{
                                imageUrl:
                                    'https://content.speedwaymotors.com/ToolboxImages/engine-recipes.jpg',
                                href: '/the-toolbox/engine-recipes~512-169'
                            }}
                        />
                        <MediaContent
                            title={'LS Swap'}
                            content={{
                                imageUrl:
                                    'https://content.speedwaymotors.com/ToolboxImages/ls-swap.jpg',
                                href:
                                    '/the-toolbox/ls-swap~512-171'
                            }}
                        />
                    </div>
                </div>
                {recommendedArticles?.length >= 1 && <RecommendedContent recommendedArticles={recommendedArticles} />}
            </div>
        </>
    )
}

const RecommendedContent = ({ recommendedArticles }) => {
    if (!recommendedArticles || recommendedArticles?.length <= 0) {
        return false
    }

    let title = 'Recommended Articles For You'
    return (
        <>

            <Typography
                className={ClassNames(styles.toolBoxMenuTitle, styles.recommendedArticlesTitle)}
                size={1.25}
                tone={'contrast'}
                font={'heading'}
                as={'a'}
                href={'/the-toolbox/recommended'}
                onClick={() => ToolboxBladeEvent(title)()}
            >
                {title}
            </Typography>

            <div className={styles.recommendedArticles}>

                {recommendedArticles?.map((article) => {
                    const { articleDetails } = article
                    if (articleDetails) {
                        return (
                            <div key={article.articleId}
                                className={styles.marginWrapper}
                                onClick={() => {
                                    ToolboxBladeEvent("Recommended Article")()
                                }}
                            >
                                <div className={styles.recommendedArticleCardWrapper}>
                                    <ArticleCard article={articleDetails} horizontal />
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
        </>
    )
}

const MediaContent = ({ title, content }) => {
    if (!content) {
        return (
            <>
                <Typography
                    className={styles.mediaContentSectionTitle}
                    size={1.25}
                    tone={'contrast'}
                    font={'heading'}
                >
                    {title}
                </Typography>
                <Skeleton width={300} height={185} />
            </>
        )
    }

    const { imageUrl, href } = content

    return (
        <div>
            <div>
                <Typography
                    className={styles.mediaContentSectionTitle}
                    size={1.25}
                    tone={'contrast'}
                    font={'heading'}
                    as={'a'}
                    href={href}
                    onClick={() => {
                        ToolboxBladeEvent(title)()
                    }}
                >
                    {title}
                </Typography>
            </div>


            <a href={href}>
                <Image
                    className={styles.mediaContentImage}
                    src={imageUrl}
                    width={300}
                    height={185}
                />
            </a>
        </div>


    )
}

const ToolboxMenuSection = ({ title, options, href, className }) => {
    let content = null
    if (!options) {
        content = (
            <SkeletonContent
                rows={8}
                skeletonProps={{
                    className: styles.toolboxOption,
                    height: 25,
                    fullWidth: true
                }}
            />
        )
    } else {
        content = (
            <List>
                {options?.map((option) => {
                    return (
                        <>
                            <TextLink
                                size={0.875}
                               
                                as={'a'}
                                href={option.href} key={option.title}
                                onClick={() => {
                                    ToolboxBladeEvent(option.title)()
                                }}
                            >
                                {option.title}
                            </TextLink>
                            <div className={styles.toolboxOption}></div>
                        </>
                    )
                })}
            </List>
        )
    }

    return (
        <div className={ClassNames(styles.toolboxMenuSection, className)}>
            <Typography
                className={styles.toolBoxMenuTitle}
                size={1.25}
                tone={'contrast'}
                font={'heading'}
                as={href ? 'a' : undefined}
                href={href}
                onClick={() => {
                    ToolboxBladeEvent(title)()
                }}>
            {title}
                </Typography>
            {content}
        </div>
    )
}

export { ToolboxContent }
